<template>
  <b-form-group
      label="Associa tags"
      label-for="tags-component-select"
  >
    <b-form-tags
        id="tags-component-select"
        v-model="value"
        size="lg"
        class="mb-2"
        add-on-change
        no-outer-focus
    >
      <template
          v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }"
      >
        <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
          <li v-for="tag in tags" :key="tag" class="list-inline-item">
            <b-form-tag
                @remove="removeTag(tag)"
                :title="tag"
                :disabled="disabled"
                variant="info"
            >{{ tag }}</b-form-tag
            >
          </li>
        </ul>
        <b-form-select
            v-bind="inputAttrs"
            v-on="inputHandlers"
            :disabled="disabled || availableOptions.length === 0"
            :options="availableOptions"
            size="md"
        >
          <template #first>
            <option disabled value="">Scegli tra...</option>
          </template>
        </b-form-select>
      </template>
    </b-form-tags>
  </b-form-group>
</template>

<script>
export default {
  name: "TypeServicesSelectTags",
  data: () => ({
    value: [],
  }),
  computed: {
    availableOptions() {
      this.$emit('setSelect', this.value)
      return this.items.filter((opt) => this.value.indexOf(opt) === -1);
    },
    items () {
      return this.$store.state.serviceTags
    }
  },
  async created() {
    await this.getList()
  },
  methods: {
    async getList() {
      await this.$store.dispatch("setTypeServiceTag", [])
      const res = await this.axios.get('tags')
      await this.$store.dispatch("setTypeServiceTag", res.data)
    }
  }
};
</script>